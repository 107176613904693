const resource = '/sitemap';

export default axios => ({
  games(params = {}) {
    return axios.get(`${resource}/games`, { params });
  },

  categories(params = {}) {
    return axios.get(`${resource}/categories`, { params });
  },
});
