export default axios => ({
  csrf() {
    return axios.get('/auth/csrf-cookie');
  },

  login(params = {}) {
    return axios.post('/admin/auth/login', params);
  },

  logout() {
    return axios.post('/admin/auth/logout');
  },

  me() {
    return axios.get('/admin/auth/me');
  },
});
