import Login from "@/views/Login.vue";
import guest from "@/middlewares/guest";
import auth from "@/middlewares/auth";

const routes = [
  {
    path: '/',
    name: 'Login',
    meta: {
      middleware: guest,
      layout: 'EmptyLayout',
    },
    component: Login,
  },
  {
    path: '/dashboard',
    name: 'Dashboard',
    component: () => import(/* webpackChunkName: "dashboard" */ "@/views/Dashboard.vue"),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/games',
    name: 'Games',
    component: () => import(/* webpackChunkName: "games" */ "@/views/Games.vue"),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/games/:game',
    name: 'GamesEdit',
    component: () => import(/* webpackChunkName: "games-edit" */ "@/views/GamesEdit.vue"),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/categories',
    name: 'Categories',
    component: () => import(/* webpackChunkName: "categories" */ "@/views/Categories.vue"),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/categories/new',
    name: 'CategoriesCreate',
    component: () => import(/* webpackChunkName: "categories-create" */ "@/views/CategoriesCreate.vue"),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/categories/:category',
    name: 'CategoriesEdit',
    component: () => import(/* webpackChunkName: "categories-edit" */ "@/views/CategoriesEdit.vue"),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/companies',
    name: 'Companies',
    component: () => import(/* webpackChunkName: "companies" */ "@/views/Companies.vue"),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/companies/new',
    name: 'CompaniesCreate',
    component: () => import(/* webpackChunkName: "companies-create" */ "@/views/CompaniesCreate.vue"),
    meta: {
      middleware: auth,
    },
  },
  {
    path: '/companies/:company',
    name: 'CompaniesEdit',
    component: () => import(/* webpackChunkName: "companies-edit" */ "@/views/CompaniesEdit.vue"),
    meta: {
      middleware: auth,
    },
  },
  { 
    path: '*', 
    name: 'Error',
    component: () => import(/* webpackChunkName: "error" */ "@/views/Error.vue"),
    meta: {
      layout: 'EmptyLayout',
    },
  },
];

export default routes;
