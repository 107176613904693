<template>
  <component :is="layout">
    <router-view :key="$route.path" />
  </component>
</template>

<script>
const defaultLayout = 'DashboardLayout';

export default {
  name: 'App',

  computed: {
    layout() {
      return this.$route.meta.layout || defaultLayout;
    },
  },
};
</script>
