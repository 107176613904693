import Repository from "@/repositories/Repository";

const AuthRepository = Repository.get("auth");

const state = () => ({
  authenticated: false,
});

const getters = {
  authenticated(state) {
    return state.authenticated;
  },
};

const mutations = {
  SET_AUTHENTICATED(state, value) {
    state.authenticated = value;
  },
};

const actions = {
  async login({ commit }, credentials) {
    await AuthRepository.csrf();
    await AuthRepository.login(credentials);
    commit("SET_AUTHENTICATED", true);
  },

  async logOut({ commit }) {
    try {
      await AuthRepository.logout();
    } finally {
      commit("SET_AUTHENTICATED", false);
    }
  },
};

export default {
  namespaced: true,
  state,
  getters,
  mutations,
  actions,
};
