import axios from "@/common/axios";
import GameRepository from "./GameRepository";
import AuthRepository from "./AuthRepository";
import SitemapRepository from "./SitemapRepository";
import CategoryRepository from "./CategoryRepository";
import CompanyRepository from "./CompanyRepository";
import PurgeRepository from "./PurgeRepository";

const repositories = {
  games: GameRepository,
  auth: AuthRepository,
  sitemap: SitemapRepository,
  categories: CategoryRepository,
  companies: CompanyRepository,
  purge: PurgeRepository,
};

export default {
  get: name => repositories[name](axios),
};
