const resource = '/companies';

export default axios => ({
  all(params = {}) {
    return axios.get(resource, { params });
  },

  store(payload = {}) {
    return axios.post(resource, payload);
  },

  update(id, payload = {}) {
    return axios.post(`${resource}/${id}`, payload);
  },

  find(id, params = {}) {
    return axios.get(`${resource}/${id}`, { params });
  },
});
