import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import "./plugins/buefy";
import "./registerServiceWorker";
import "./assets/styles/scss/main.scss";

Vue.component('DashboardLayout', () => import(/* webpackChunkName: "dashboard-layout" */ "./layouts/DashboardLayout.vue"));
Vue.component('EmptyLayout', () => import(/* webpackChunkName: "empty-layout" */ "./layouts/EmptyLayout.vue"));

Vue.config.productionTip = false;

new Vue({
  router,
  store,
  render: h => h(App),
}).$mount('#app');
