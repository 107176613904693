import Repository from "@/repositories/Repository";

const SitemapRepository = Repository.get('sitemap');
const CategoryRepository = Repository.get('categories');
const GameRepository = Repository.get('games');
const CompanyRepository = Repository.get('companies');

const state = () => ({
  categories: [],
  games: [],
  companies: [],
});

const getters = {
  categories(state) {
    return state.categories;
  },

  parentCategories(state) {
    return state.categories.filter(category => category.parent == null).sort((a, b) => a.title.localeCompare(b.title));
  },

  games(state) {
    return state.games;
  },

  findGamesByWildcard: state => search => {
    return state.games.filter(game => game.title.toLowerCase().indexOf(search.toLowerCase()) > -1);
  },

  companies(state) {
    return state.companies;
  },
};

const mutations = {
  SET_CATEGORIES(state, value) {
    state.categories = value;
  },

  ADD_CATEGORY(state, { title, slug, distribution_id, parent }) {
    state.categories.push({
      title,
      slug,
      distribution_id,
      parent,
    });
  },

  EDIT_CATEGORY(state, { title, slug, distribution_id, parent }) {
    const category = state.categories.find(category => category.distribution_id === distribution_id);
    Object.assign(category, {
      title,
      slug,
      parent,
    });
  },

  SET_GAMES(state, value) {
    state.games = value;
  },

  EDIT_GAME(state, { title, published_at, distribution_id }) {
    const game = state.games.find(game => game.distribution_id === distribution_id);
    Object.assign(game, {
      title,
      published_at,
    });
  },

  DELETE_GAME(state, value) {
    const index = state.games.findIndex(game => game.distribution_id == value);
    state.games.splice(index, 1);
  },

  SET_COMPANIES(state, value) {
    state.companies = value;
  },

  ADD_COMPANY(state, value) {
    state.companies.push(value);
  },

  EDIT_COMPANY(state, value) {
    const company = state.companies.find(company => company.id === value.id);
    Object.assign(company, {
      name: value.name,
    });
  },
};

const actions = {
  async fetchCategories({ commit, rootState }) {
    const response = await SitemapRepository.categories({
      language: rootState.config.defaultApiLocale,
      state: 'preview',
    });
    commit('SET_CATEGORIES', response.data);
  },

  async addCategory({ commit, rootState }, payload) {
    return CategoryRepository.store(payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(response => {
      if (rootState.config.defaultApiLocale === payload.get('language')) {
        commit('ADD_CATEGORY', response.data);
      }
      return response;
    });
  },

  async editCategory({ commit, rootState }, payload) {
    return CategoryRepository.update(payload.get('id'), payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(response => {
      if (rootState.config.defaultApiLocale === payload.get('language')) {
        commit('EDIT_CATEGORY', response.data);
      }
      return response;
    });
  },

  async fetchGames({ commit, rootState }) {
    const response = await SitemapRepository.games({
      language: rootState.config.defaultApiLocale,
      state: 'preview',
    });
    commit('SET_GAMES', response.data.map(game => ({
      title: game.title,
      published_at: game.published_at,
      distribution_id: game.distribution_id,
    })));
  },

  async editGame({ commit, rootState }, payload) {
    return GameRepository.update(payload.get('id'), payload, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    }).then(response => {
      if (rootState.config.defaultApiLocale === payload.get('language')) {
        commit('EDIT_GAME', response.data);
      }
      return response;
    });
  },

  async deleteGame({ commit }, payload) {
    return GameRepository.destroy(payload.get('id'), payload).then(response => {
      commit('DELETE_GAME', payload.get('id'));
      return response;
    });
  },

  async fetchCompanies({ commit }) {
    const response = await CompanyRepository.all({
      state: 'preview',
    });
    commit('SET_COMPANIES', response.data);
  },

  async addCompany({ commit }, payload) {
    return CompanyRepository.store(payload).then(response => {
      commit('ADD_COMPANY', response);
      return response;
    });
  },

  async editCompany({ commit }, payload) {
    return CompanyRepository.update(payload.get('id'), payload).then(response => {
      commit('EDIT_COMPANY', response);
      return response;
    });
  },
};

export default {
    namespaced: true,
    state,
    getters,
    mutations,
    actions,
};
