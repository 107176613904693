import Vue from "vue";
import VueRouter from "vue-router";
import store from "@/store";
import routes from "./routes";

Vue.use(VueRouter);

const router = new VueRouter({
  routes,
  mode: 'history',
});

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.middleware)) {
    const middleware = to.meta.middleware;

    const context = {
      to, 
      from, 
      next, 
      store 
    };

    middleware({ ...context });
  } else {
    // make sure you always call next()
    next();
  }
});

export default router;
