const resource = '/categories';

export default axios => ({
  store(payload = {}, headers = {}) {
    return axios.post(resource, payload, headers);
  },

  update(id, payload = {}, headers = {}) {
    return axios.post(`${resource}/${id}`, payload, headers);
  },

  find(slug, params = {}) {
    return axios.get(`${resource}/${slug}`, { params });
  },
});
