const resource = '/games';

export default axios => ({
  store(payload = {}) {
    return axios.post(resource, payload);
  },

  update(id, payload = {}, headers = {}) {
    return axios.post(`${resource}/${id}`, payload, headers);
  },

  destroy(id, payload = {}) {
    return axios.post(`${resource}/${id}`, payload);
  },

  find(slug, params = {}) {
    return axios.get(`${resource}/${slug}`, { params });
  },

  findByScheduled(params = {}) {
    return axios.get('/scheduled', { params });
  },
});
